import React, { Component } from 'react';
// import { hotjar } from 'react-hotjar';

class BookingScript extends Component {
  componentDidMount() {
    window.BOOKING_PAGE_LINK = process.env.NEXT_PUBLIC_BOOKING_PAGE_LINK;
    // window.BOOKING_PAGE_LINK = process.env.NEXT_PUBLIC_BOOKING_PAGE_LINK_BETA;
    // window.BOOKING_FILE_PATH = process.env.NEXT_PUBLIC_BOOKING_FILE_PATH_BETA;
    window.BOOKING_FILE_PATH = process.env.NEXT_PUBLIC_BOOKING_FILE_PATH;
    window.LOADING_BOOKING = process.env.NEXT_PUBLIC_LOADING_BOOKING;
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = '../../../JS/bookingScript.js';
    this.div.appendChild(script);
    // hotjar.initialize(process.env.HOTJAR_ID, 6);
    window.document.addEventListener('click', function (e) {
      if (e.target.closest('[href^="tel:"]')) {
        gtag('event', 'conversion', {
          send_to: 'AW-11422420127/0sDvCKOBmaoaEJ-Z0cYq',
        });
      }
      if (e.target.closest('[href^="mailto:"]')) {
        console.log('mail');
        gtag('event', 'conversion', {
          send_to: 'AW-11422420127/9csPCPGbiqoaEJ-Z0cYq',
        });
      }
    });
  }
  render() {
    return (
      <div className='App' ref={(el) => (this.div = el)}>
        <a
          className='open_booking-iframe-navbutton'
          id='open_booking-iframe-image'
        >
          <img
            width='36'
            height='157'
            border='none'
            src='../icons/booking.png'
            alt='Book an appointment with VACCIN DK'
          />
        </a>
      </div>
    );
  }
}

export default BookingScript;
